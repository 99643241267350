import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/theme/layout'
import Seo from '../../components/seo'

import './legal.scss'


export default function termsOfServices() {
  return (
    <Layout>
        <Seo title='Terms of services policy' />
        <div className='legal-container'>
            <h1>Terms of services</h1>

            <p>The following Terms of Service (“Terms” or “Agreement”) constitute a legal agreement between you or the entity or company that you represent (“Customer” or “You”) and Qiwio (“Qiwio”),
            which governs Customer’s use of the Services. The Services can be accessed as a paid service having the specifications outlined in the respective Plan, for which Customer pays a monthly
            or yearly subscription fee (the “Paid Version”). Customer’s use of the Services is subject to (a) the terms and conditions set forth below and (b) Qiwio’s privacy policy, found at <Link to='/legal/privacy'>qiwio.io/legal/privacy</Link> and
            incorporated herein by reference and (c) Qiwio’s data processing addendum, found at <Link to='/legal/data-processing'>qiwio.io/legal/data-processing</Link>, so please take the time to fully understand how these Terms, Qiwio’s privacy policy
            and data processing addendum govern Customer’s relationship with Qiwio and Customer’s use of the Services. The Services are available only to individuals who are at least 18 years old. If Customer is an
            individual, Customer represents and warrants that Customer is at least 18 years old.</p>

            <p>CUSTOMER’S RIGHT TO USE THE SERVICES IS EXPRESSLY CONDITIONED ON ACCEPTANCE OF THESE TERMS. BY CLICKING ON THE “ACCEPT” BUTTON AND/OR USING THE SERVICES, YOU ARE UNCONDITIONALLY CONSENTING TO BE BOUND BY AND
            ARE BECOMING A PARTY TO THE TERMS OF THIS AGREEMENT. IF YOU ARE ACCESSING THE SERVICES ON BEHALF OF YOUR EMPLOYER OR ANOTHER ENTITY, YOU REPRESENT AND WARRANT THAT YOU HAVE THE AUTHORITY TO AGREE TO THESE TERMS
            ON ITS BEHALF. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS, YOU MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT USE THE SERVICES.</p>

            <ol>
                <li>
                    <strong>Definitions</strong>

                    <ul>
                        <li><strong>“Confidential Information”</strong> means all trade secrets, know-how, inventions, developments, software and other financial, business or technical information disclosed by or for a party in relation to this
                        Agreement, but not including any information the receiving party can demonstrate is (a) already rightfully known by it without restriction, (b) rightfully furnished to it by a third party without restriction
                        and without breach of any obligation to the disclosing party, (c) generally available to the public without breach of this Agreement or (d) independently developed by it without reliance on the Confidential
                        Information of the disclosing party. All pricing information is Qiwio’s Confidential Information.</li>
                        <li><strong>“Content”</strong> means all text, software, scripts, graphics, photos, sounds, music, videos, audiovisual combinations, interactive features and other materials that may be viewed on, accessed through, or contributed
                        to the Services.</li>
                        <li><strong>“Customer Content”</strong> means Content contributed to the Services by Customer.</li>
                        <li><strong>“Customer Data”</strong> means all Customer registration information and other transaction data collected, processed and retained by Qiwio in connection with providing the Services.</li>
                        <li><strong>“Plan”</strong> means Qiwio’s free or any paid plans, as applicable and as further described on Qiwio’s website available at: <Link to='/pricing'>www.qiwio.io/pricing</Link>.</li>
                        <li><strong>“Services”</strong> means the services hosted by Qiwio and provided to Customer under this Agreement.</li>
                        <li><strong>“Systems”</strong> means modems, servers, software, network and communications equipment and ancillary services that are owned, controlled or procured by Customer.</li>
                        <li><strong>“Updates”</strong> means any patch, revision or update to the Services delivered by Qiwio.</li>
                        <li><strong>“Updates”</strong> stands for the Stockholm Chamber of Commerce.</li>
                    </ul>
                </li>

                <li>
                    <strong>Services</strong>

                    <ol>
                        <li>2.1 Services
                            <p>Subject to all terms and conditions of this Agreement, Qiwio will use commercially reasonable efforts to provide the Services. Qiwio may provide the Services to Customer directly, or indirectly using contractors or
                            other third party vendors or service providers.</p>
                        </li>
                        <li>2.2 Bandwidth
                            <p>Use of the Services by Customer shall not unreasonably interfere with use of the Services by other Qiwio customers. All Plans have an associated monthly bandwidth limit as specified on the pricing page of the Qiwio
                            website (<Link to='/pricing'>www.qiwio.io/pricing</Link>). Monthly limits are calculated based on calendar months and are based on the date of account activation. Once an account reaches its monthly bandwidth limit You will be notified and given
                            the option of (a) upgrading to a plan with a higher bandwidth limit, or (b) paying for extra bandwidth at the then current overage rate for your account (unless otherwise previously mutually agreed upon).</p>
                        </li>
                        <li>2.3 Security measures
                            <p>Customer may access the Services as Qiwio instructs through a combination of one or more user names and passwords.</p>
                        </li>
                        <li>2.4 Passwords
                            <p>Customer shall take full responsibility and liability for the security of each of its user names and passwords, and shall be solely responsible for all use of the Services through such user names or passwords. Customer agrees to
                            immediately notify Qiwio of any unauthorized use of the Services or any other breach of security known to Customer.</p>
                        </li>
                        <li>2.5 Prohibited use
                            <p>As a condition of use of the Services, You promise not to use the Services for any purpose that is prohibited by these Terms, or prohibited by the Qiwio <Link to='/legal/content-standards-policy'>Content Standards policy.</Link></p>
                            <p>By way of example, and not as a limitation, You shall not upload, submit, distribute, facilitate any of the foregoing, or otherwise use the Services or interact with the Services in a manner that:</p>

                            <ul>
                                <li>infringes or violates the intellectual property rights or any other rights of any other person or entity (including Qiwio);</li>
                                <li>violates any law or regulation;</li>
                                <li>is harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, pornographic, contains or depicts nudity, or otherwise objectionable, as determined by Qiwio in its sole discretion;</li>
                                <li>jeopardizes the security of your Qiwio account or anyone else’s (such as allowing someone else to log in to the Services as you)</li>
                                <li>attempts, in any manner, to obtain the password, account, or other security information from any other user;</li>
                                <li>violates the security of any computer network, or cracks any passwords or security encryption codes;</li>
                                <li>runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or any processes that run or are activated while you are not logged into the Services, or that otherwise interfere with the proper working of the Services (including by placing an unreasonable load on the Services’ infrastructure);</li>
                                <li>“crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Services or Content (through use of manual or automated means);</li>
                                <li>copies or stores any significant portion of the Content;</li>
                                <li>decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the Services.</li>
                            </ul>

                            <p>Additionally, You shall not (directly or indirectly) or permit any third party to: (a) use any of Qiwio’s Confidential Information to create any software, documentation or service that is similar to the Services or any documentation
                            provided in connection therewith; (e) modify, translate, or otherwise create derivative works of any part of the Services, (f) copy, license, sublicense, sell, resell, encumber, rent, lease, time-share, distribute, transfer or otherwise
                            use or exploit or make available the Services in any service bureau arrangement or otherwise for the benefit of any third party without the prior written consent of Qiwio. You shall abide by all applicable local, state, national and international
                            laws and regulations, including, without limitation, any export control laws or regulations of Sweden or any other relevant jurisdiction. Access to the Services by “bots” or other automated methods is not permitted.</p>
                        </li>
                        <li>2.6 Changes to Services
                            <p>Qiwio reserves the right to modify or discontinue any Services or Plan (in whole or in part) at any time.</p>
                        </li>
                        <li>2.7 Changes to Terms
                            <p>We reserve the right to change the Terms at any time, but if we do, we will bring it to your attention by placing a notice on the Qiwio website, by sending you an email, and/or by some other means. If you don’t agree with the new
                            Terms, you are free to reject them; unfortunately, that means you will no longer be able to use the Services. If you use the Services in any way after a change to the Terms is effective, that means you agree to all of the changes.
                            Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing and signed by both you and us.</p>
                        </li>
                        <li>2.8 Limitations
                            <p>Qiwio will not be responsible or liable for any failure in the Services resulting from or attributable to (a) Customer’s Systems, (b) network, telecommunications or other service or equipment failures outside of Qiwio’s facilities, (c) Customer’s or third party’s products, services, negligence, acts or omissions,
                            (d) any force majeure or cause beyond Qiwio’s reasonable control, (e) scheduled maintenance or (f) unauthorized access, breach of firewalls or other hacking by third parties.</p>
                        </li>
                        <li>2.9 Systems
                            <p>Customer shall obtain and operate all Systems needed to connect to, access or otherwise use the Services, and provide all corresponding backup, recovery and maintenance services. Customer shall ensure that all Systems are compatible with the Services. Customer shall maintain the integrity and security of its Systems (physical, electronic and otherwise).</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Support and maintenance</strong>

                    <ol>
                        <li>3.1 Support
                            <p>Qiwio will use commercially reasonable efforts to provide Customer with support and maintenance services for the Services in accordance with its standard practices (as amended from time to time). Customer agrees that Qiwio will have the right to charge in accordance with its then current policies for any support services resulting from problems, errors or
                            inquiries relating to Systems or any other network, equipment, service or software not owned, controlled or procured by Qiwio.</p>
                        </li>
                        <li>3.2 Updates
                            <p>Qiwio shall have no obligation to provide Updates, except that Qiwio will provide Customer with any Update that it makes generally available without charge to its similar customers.</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Proprietary rights</strong>

                    <ol>
                        <li>4.1 Services
                            <p>Except for Customer Content, Qiwio (and its licensors) own all right, title and interest in and to the Services and all modifications, enhancements and Updates to the Services (including all intellectual property and proprietary rights embodied therein). Qiwio reserves all rights not expressly granted hereunder. Customer shall not take any action inconsistent
                            with such rights. Customer shall not alter, obscure or remove any printed or on-screen trademark, patent legend or other proprietary or legal notice.</p>
                        </li>
                        <li>4.2 Customer content
                            <p>Customer owns all right, title and interest in and to the Customer Content. You hereby grant Qiwio a royalty-free, fully paid-up, irrevocable, worldwide license to exploit the Customer Content as necessary to provide the Services to you. You are responsible for all Customer Content, and you represent and warrant you have all rights necessary to grant the rights
                            in Customer Content set forth in this Agreement.</p>
                        </li>
                        <li>4.3 Customer data
                            <p>As between the parties, Customer shall own all Customer Data. Qiwio shall not disclose to third parties or use any Customer Data except as reasonably necessary to provide the Services or to comply with any legal, regulatory or similar requirement or investigation. Notwithstanding the foregoing, during and after the term of this Agreement, Qiwio may use Customer
                            Data, as combined with other Qiwio customers’ data, to improve and/or market the Services. Customer hereby grants Qiwio a nonexclusive and royalty-free right and license to use the Customer Data solely for the purposes described above. Customer agrees to create archival copies or backup copies of all Customer Data.</p>
                        </li>
                        <li>4.4 Indemnity
                            <p>Customer agrees to indemnify and hold Qiwio harmless from all claims, damages, liabilities, losses, costs and expenses (including lawyers’ fees) (a) arising out of any use or disclosure of Customer Data or Customer Content in connection with the provision of Services, (b) to comply with any legal, regulatory or similar requirement or investigation, and (c) arising
                            out of, relating to, or resulting from any third-party claim against Qiwio from Customer’s failure to comply with any of its obligations under applicable Data Protection Laws, as defined in the Data Processing Addendum, if applicable.</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Confidentiality</strong>

                    <ol>
                        <li>5.1 Confidentiality
                            <p>Except for the specific rights granted by this Agreement, the receiving party shall not use or disclose any of the other’s Confidential Information without its written consent, and shall use reasonable care to protect the other’s Confidential Information, including ensuring that its employees and contractors with access
                            (a) have a need to know for the purposes of this Agreement and (b) are bound by obligations of confidentiality at least as protective as those provided herein. Each party shall be responsible for any breach of confidentiality by its employees and contractors. Each party may disclose only the general nature, but not the specific
                            terms, of this Agreement without the prior consent of the other party; provided, either party may provide a copy of this Agreement or otherwise disclose its terms in connection with any financing transaction or due diligence inquiry, provided that the party to whom such information is disclosed is bound by confidentiality
                            obligations substantially similar to those herein and the party disclosing such information is responsible for any breaches of confidentiality by the party to whom such information is disclosed.</p>

                            <p>We use third party social media platforms (e.g. Facebook, etc.) to show you customized social media ads.</p>
                        </li>
                        <li>5.2 Compelled Disclosure
                            <p>Nothing herein shall prevent a receiving party from disclosing any Confidential Information as necessary pursuant to any court order, lawful requirement of a governmental agency or when disclosure is required by operation of law (including disclosures pursuant to any applicable securities laws and regulations); provided,
                            that prior to any such disclosure, the receiving party shall use reasonable efforts to (a) promptly notify the disclosing party in writing of such requirement to disclose and (b) cooperate with the disclosing party in protecting against or minimizing any such disclosure or obtaining a protective order.</p>
                        </li>
                        <li>5.3 Effect of Termination
                            <p>Promptly after any termination of this Agreement (or at the disclosing party’s request at any other time), the receiving party shall return all of the other’s tangible Confidential Information, permanently erase all Confidential Information from any storage media and destroy all information, records and materials developed
                            therefrom. In the event of such request from You to Qiwio, Qiwio will no longer have any obligation to provide the Services to You. Notwithstanding the foregoing, Qiwio may retain and use Customer Data, as combined with other Qiwio customers’ data, solely to improve and/or market the Services, even after termination of the
                            provision of Services to You.</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Paid version payments</strong>

                    <ol>
                        <li>6.1 Fees
                            <p>Customer agrees to pay Qiwio the fees, in the amounts and at the times specified in the selected Plan.</p>
                        </li>
                        <li>6.2 Credit cart information
                            <p>In order to set up an account with Qiwio, Customer must provide Qiwio with accurate and complete billing information including legal name, address, telephone number, and a valid credit card. Customer’s card will never be charged without its authorization. By submitting such credit card information, Customer gives Qiwio
                            permission to charge all fees incurred through its account to the designated credit card. Qiwio reserves the right to terminate this Agreement in accordance with Section 9.2 hereto if Customer does not provide a valid credit card for the payment of fees hereunder.</p>
                        </li>
                        <li>6.3 Payment terms
                            <p>The Services are billed in advance on a monthly or annual basis, depending upon which payment plan is chosen by Customer. Qiwio will not provide refunds or credits in the case of cancellations, downgrades, or when there are unused portions of the Services on an open account. For any Services upgrade, the additional fee
                            for the Services upgrade for the remainder of the current term (i.e. either month or year) will automatically be charged to Customer at the time of the upgrade. All future recurring charges for the Services will follow the monthly or annual billing cycle (as chosen by the Customer). For Customers that elect to be invoiced,
                            all amounts are due and payable within 10 days from the date of the invoice.</p>
                        </li>
                        <li>6.4 Taxes
                            <p>All payments are exclusive of federal, state, local and foreign taxes, duties, tariffs, levies, withholdings and similar assessments (including without limitation, sales taxes, use taxes and value added taxes), which we will charge to you as applicable. You agree to pay and will be responsible for paying, withholding,
                            filing, and reporting all taxes, duties, and other governmental assessments associated with your activity in connection with the Services, excluding taxes based upon Qiwio’s net income. All amounts due hereunder shall be grossed-up for any withholding taxes imposed by any foreign government.</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Limited Warranty and Disclaimers</strong>

                    <ol>
                        <li>7.1 Paid version limited warranty
                            <p>Qiwio warrants that it will provide the Paid Version in a manner consistent with general industry standards reasonably applicable to the provision thereof. Notwithstanding the foregoing, the Paid Version may be temporarily unavailable, for example, when deemed reasonably necessary or prudent by Qiwio to repair, maintain
                            or upgrade the Paid Version or for causes beyond Qiwio’s reasonable control. Qiwio will notify Customer at least 48 hours in advance of any known planned Paid Version-related outages.</p>
                        </li>
                        <li>7.2 Disclaimers
                            <p>EXCEPT AS SPECIFICALLY PROVIDED HEREIN, THE SERVICES ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND. QIWIO DOES NOT WARRANT THAT THE SERVICES WILL MEET CUSTOMER’S REQUIREMENTS OR THAT THEIR OPERATION WILL BE UNINTERRUPTED OR ERROR-FREE. TO THE FULLEST EXTENT PERMITTED BY LAW, QIWIO HEREBY DISCLAIMS (FOR ITSELF AND
                                ITS SUPPLIERS) ALL OTHER WARRANTIES, WHETHER EXPRESS OR IMPLIED, ORAL OR WRITTEN, WITH RESPECT TO THE SERVICES INCLUDING, WITHOUT LIMITATION, ALL IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, QUIET ENJOYMENT, INTEGRATION, MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE AND ALL WARRANTIES ARISING FROM ANY
                                COURSE OF DEALING, COURSE OF PERFORMANCE OR USAGE OF TRADE.</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Limitation of liability</strong>

                    <ol>
                        <li>8.1 Paid version limitation of liability
                            <p>EXCEPT FOR DEATH, BODILY INJURY OR FRAUD, AMOUNTS OWED TO THIRD PARTIES PURSUANT TO THE INDEMNIFICATION OBLIGATIONS HEREIN, ANY BREACHES OF SECTION 2.5 (PROHIBITED USES) OR SECTION 6.1 (FEES), OR ANY EXCLUSION OR LIMITATION OF LIABILITY THAT IS VOID, PROHIBITED OR UNENFORCEABLE BY APPLICABLE LAW, IN NO EVENT SHALL
                            EITHER PARTY (OR ITS SUPPLIERS) BE LIABLE CONCERNING THE SUBJECT MATTER OF THIS AGREEMENT, REGARDLESS OF THE FORM OF ANY CLAIM OR ACTION (WHETHER IN CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE), FOR ANY (A) MATTER BEYOND ITS REASONABLE CONTROL (INCLUDING ANY ERROR OR DAMAGE ATTRIBUTABLE TO ANY NETWORK OR SYSTEM),
                            (B) LOSS OR INACCURACY OF DATA, LOSS OR INTERRUPTION OF USE, OR COST OF PROCURING SUBSTITUTE TECHNOLOGY, GOODS OR SERVICES, (C) INDIRECT, PUNITIVE, INCIDENTAL, RELIANCE, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES INCLUDING, BUT NOT LIMITED TO, LOSS OF BUSINESS, REVENUES, PROFITS OR GOODWILL, OR (D) DIRECT DAMAGES, IN
                            THE AGGREGATE, IN EXCESS OF THE AMOUNTS PAID TO QIWIO HEREUNDER WITH RESPECT TO THE SERVICES THAT GAVE RISE TO THE CLAIM DURING THE TWELVE-MONTH PERIOD PRIOR TO THE DATE THE CAUSE OF ACTION AROSE, EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THESE LIMITATIONS ARE INDEPENDENT FROM ALL OTHER
                            PROVISIONS OF THIS AGREEMENT AND SHALL APPLY NOTWITHSTANDING THE FAILURE OF ANY REMEDY PROVIDED HEREIN. THE TERMS OF THIS SECTION SHALL APPLY TO ANY DATA PROCESSING ADDENDUM EXECUTED BY THE PARTIES.</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Term and termination</strong>

                    <ol>
                        <li>9.1 Term
                            <p>This Agreement shall commence on the date that this Agreement is accepted. With respect to a Paid Version user, this Agreement shall continue in effect for the initial term specified in the Plan (or if no such term is specified, then for the length of your billing cycle), unless this Agreement is terminated earlier
                            as permitted in Section 9.2. At the end of such initial term, the Agreement will be extended automatically for additional terms equivalent to your billing cycle (i.e. 1-month terms or 1-year terms, as applicable), unless your Plan says otherwise or this Agreement is terminated earlier as permitted in this Section 9.1
                            or in Section 9.2. Either party may elect not to have this Agreement extend automatically by giving written notice of such election to the other party at least 1 month prior to the end of the then current initial or renewal term. Customer is solely responsible for properly notifying Qiwio of its election not to have
                            this Agreement automatically renew by following the cancellation directions available in Customer’s Qiwio account.</p>
                        </li>
                        <li>9.2 Termination of the paid version
                            <p>With respect to the Paid Version, this Agreement may be earlier terminated by either party, in whole or in part, (a) if the other party materially breaches a provision of this Agreement and fails to cure such breach within 10 days (5 days in the case of non-payment) after receiving written notice of such breach
                            from the non-breaching party, or (b) immediately upon written notice, if the other party makes any assignment for the benefit of creditors, or a receiver, trustee in bankruptcy or similar officer is appointed to take charge of any or all of the other party’s property, or the other party seeks protection under any
                            bankruptcy, receivership, trust deed, creditors arrangement, composition or comparable proceeding or such a proceeding is instituted against the other party and is not dismissed within 90 days, or the other party becomes insolvent or, without a successor, dissolves, liquidates or otherwise fails to operate in the
                            ordinary course.</p>
                        </li>
                        <li>9.3 Effects of termination
                            <p>Upon any expiration or termination of this Agreement, all rights, obligations and licenses of the parties shall cease, except that (a) all obligations that accrued prior to the effective date of termination (including without limitation, all payment obligations) and remedies for breach of this Agreement shall
                            survive, (b) Qiwio may, but shall not be obligated to, delete archived Customer Data (provided, Paid Version customers, upon written request received by Qiwio within 30 days after the effective date of termination of their accounts, shall be given a copy of their archived Customer Data in the currently existing
                            archived format) and (c) the provisions of Sections 4 (Proprietary Rights), 5 (Confidentiality), 6 (Paid Version Payments) (only with respect to amounts incurred prior to the effective date of termination), 7.2 (Disclaimers), 8 (Limitation of Liability), 11 (General Provisions) and this Section 9.3
                            (Effects of Termination) shall survive.</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Copyright infringement</strong>

                    <ol>
                        <li>10.1 General policy
                            <p>It is Qiwio’s policy to (a) block access to or remove Content that it believes in good faith to be copyrighted material that has been illegally copied and distributed by any of its affiliates, content providers, or users; and (b) remove and discontinue service to repeat offenders.</p>
                        </li>
                        <li>10.2 Procedure for reporting copyright infringement
                            <p>If You believe that Content residing on or accessible through the Qiwio website or Services infringes a copyright, please send a notice of copyright infringement containing the following information to the Designated Agent listed below:</p>

                            <ol>
                                <li>A physical or electronic signature of a person authorized to act on behalf of the owner of the copyright that has been allegedly infringed;</li>
                                <li>Identification of works or materials being infringed;</li>
                                <li>Identification of the Content that is claimed to be infringing including information regarding the location of the Content that the copyright owner seeks to have removed, with sufficient detail so that Qiwio is capable of finding and verifying its existence;</li>
                                <li>Contact information about the notifier including address, telephone number and, if available, e-mail address;</li>
                                <li>A statement that the notifier has a good faith belief that the Content is not authorized by the copyright owner, its agent, or the law; and</li>
                                <li>A statement made under penalty of perjury that the information provided is accurate and the notifying party is authorized to make the complaint on behalf of the copyright owner.</li>
                            </ol>
                        </li>
                        <li>10.3 Once proper bona fide infringement notification is recieved by designated agent
                            <p>It is Qiwio’s policy:</p>

                            <ol>
                                <li>to remove or disable access to the infringing Content;</li>
                                <li>to notify the Content provider or user that it has removed or disabled access to the Content; and</li>
                                <li>that repeat offenders will have the infringing Content removed from the system and that Qiwio will terminate such content provider’s or user’s access to the Services.</li>
                            </ol>
                        </li>
                        <li>10.4 Procedure to supply a counter-notice to the designated agent
                            <p>If the Content provider, or user believes that the Content that was removed or to which access was disabled is either not infringing, or the Content provider or user believes that it has the right to post and use such Content from the copyright owner, the copyright
                            owner’s agent, or pursuant to the law, the Content provider or user must send a counter-notice containing the following information to the Designated Agent listed below:</p>

                            <ol>
                                <li>A physical or electronic signature of the Content provider or user;</li>
                                <li>Identification of the Content that has been removed or to which access has been disabled and the location at which the Content appeared before it was removed or disabled;</li>
                                <li>A statement that the Content provider or user has a good faith belief that the Content was removed or disabled as a result of mistake or a misidentification of the Content; and</li>
                                <li>Content provider’s or user’s name, address, telephone number, and, if available, e-mail address and a statement that such person or entity consents to the jurisdiction of the Court for the judicial district in which the Content provider’s or user’s address
                                is located, or if the Content provider’s or user’s address is located outside Sweden, for any judicial district in which Qiwio is located, and that such person or entity will accept service of process from the person who provided notification of the alleged infringement.</li>
                            </ol>

                            <p>If a counter-notice is received by the Designated Agent, Qiwio may send a copy of the counter-notice to the original complaining party informing that person that it may replace the removed Content or cease disabling it in 10 business days.
                            Unless the copyright owner files an action seeking a court order against the Content provider or user, the removed Content may be replaced, or access to it restored, in 10 to 14 business days or more after receipt of the counter-notice, at Qiwio’s discretion.</p>
                        </li>
                        <li>10.5 Designated agent to recieve notification of claimed infringement
                            <p>Please contact Qiwio’s Designated Agent to Receive Notification of Claimed Infringement at the following address:</p>
                            <p>Designated Agent <br />
                            c/o Qiwio AB <br />
                            Address: Kristinebergsvägen 22. 302 41 Halmstad, Sweden <br />
                            Email: <Link to='mailto: support@qiwio.io'>support@qiwio.io</Link></p>
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>General provisions</strong>

                    <ol>
                        <li>11.1 Third party sites
                            <p>The Services may contain links or connections to third party websites or services that are not owned or controlled by Qiwio. When you access third party websites or use third party services, you accept that there are risks in doing so, and that
                            Qiwio is not responsible for such risks. We encourage you to be aware when you leave the Services and to read the terms and conditions and privacy policy of each third party website or service that you visit or utilize.</p>
                        </li>
                        <li>11.2 Entire agreement
                            <p>This Agreement, together with Qiwio’s privacy policy and the applicable Plan(s), constitutes the entire agreement, and supersedes all prior negotiations, understandings or agreements (oral or written), between the parties about the subject
                            matter of this Agreement. In the event of any conflict or inconsistency between the Agreement and the Plan, the terms and conditions in the Plan will prevail and be controlling, and the terms and conditions in the Agreement will prevail and be
                            controlling over terms set forth in Customer’s purchase order (or other similar document). Customer’s purchase order is only effective as its unqualified commitment to access and pay for the Services upon the terms (and only the terms) set
                            forth herein. No waiver, consent or, except as expressly provided herein, modification of these Terms shall bind either party unless in writing and signed by the party against which enforcement is sought. The failure of either party to enforce
                            its rights under this Agreement at any time for any period will not be construed as a waiver of such rights. If any provision of this Agreement is determined to be illegal or unenforceable, that provision will be limited or eliminated to the
                            minimum extent necessary so that this Agreement will otherwise remain in full force and effect and enforceable. If this Agreement is required to be registered with any governmental authority, Customer shall cause such registration to be made
                            and shall bear any expense or tax payable in respect thereof.</p>
                        </li>
                        <li>11.3 Governing law
                            <p>This Agreement shall be governed by and construed in accordance with the laws of Sweden, without regard to its conflicts of law provisions. Neither Swedens Convention on Contracts for the International Sale of Goods nor any enactment of the
                            Uniform Computer Information Transactions Act shall apply to this Agreement. In any action or proceeding to enforce or interpret this Agreement, the prevailing party will be entitled to recover from the other party its costs and expenses
                            (including reasonable lawyer fees) incurred in connection with such action or proceeding and enforcing any judgment or order obtained.</p>
                        </li>
                        <li>11.4 Disputes
                            <p>Any dispute, controversy or claim arising out of or in connection with the Agreement, or the breach, termination or invalidity thereof, shall be finally settled by arbitration administered by the Arbitration Institute of the Stockholm
                            Chamber of Commerce (the “SCC”). The Rules for Expedited Arbitrations shall apply, unless the SCC in its discretion determines, taking into account the complexity of the case, the amount in dispute and other circumstances, that the
                            Arbitration Rules shall apply. In the latter case, the SCC shall also decide whether the Arbitral Tribunal shall be composed of one or three arbitrators. The seat of arbitration shall be Gothenburg. The language to be used in the arbitral
                            proceedings shall be English unless the Parties agree otherwise.</p>
                        </li>
                        <li>11.5 Remedies
                            <p>Except as specifically provided otherwise, each right and remedy in this Agreement is in addition to any other right or remedy, at law or in equity. Each party agrees that, in the event of any breach or threatened breach of Section 5,
                            the non-breaching party may suffer irreparable damage for which it may have no adequate remedy at law. Accordingly, the non-breaching party shall be entitled to seek injunctive and other equitable remedies to prevent or restrain such breach
                            or threatened breach, without the necessity of posting any bond.</p>
                        </li>
                        <li>11.6 Notices
                            <p>Except as otherwise provided in Sections 9.1 and 10, any notice or communication hereunder shall be in writing and either personally delivered or sent recognized express delivery courier or certified or registered mail, prepaid and
                            return receipt requested, addressed to the other party at its address specified in the Plan, or at such other address designated in a subsequent notice. All notices shall be in English, effective upon receipt.</p>
                        </li>
                        <li>11.7 Assignment
                            <p>This Agreement and the rights and obligations hereunder may not be assigned, in whole or in part, by either party without the other party’s written consent (which shall not be unreasonably withheld). However, without consent, either
                            party may assign this Agreement to any successor to all or substantially all of its business which concerns this Agreement (whether by sale of assets or equity, merger, consolidation or otherwise). Any attempted transfer in violation
                            hereof will be void and of no effect. This Agreement shall be binding upon, and inure to the benefit of, the successors, representatives and permitted assigns of the parties hereto.</p>
                        </li>
                        <li>11.8 Independent contractors
                            <p>The parties shall be independent contractors under this Agreement, and nothing herein will constitute either party as the employer, employee, agent or representative of the other party, or both parties as joint venturers or
                            partners for any purpose.</p>
                        </li>
                        <li>11.9 Publicity
                            <p>Neither party will make public announcements or issue press releases relating to this Agreement or the terms hereof without the prior written consent of the other party, which consent shall not be unreasonably withheld or delayed.</p>
                        </li>
                    </ol>
                </li>
            </ol>

            <p><strong>Last updated: May 24, 2022</strong></p>
        </div>
    </Layout>
  )
}
